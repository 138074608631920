<template>
    <v-navigation-drawer
      v-model="toogle"
      right
      fixed
      width="500"
      temporary
      :overlay-opacity="0.1"
      class="content-scroll elevation-0 pa-6"
    >
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <h1>Detalles del Mensaje</h1>
          <v-chip
            :color="details.status_code === '200' ? 'icono' : 'red'"
            class="ma-1 font-weight-medium"
            label
            small
            dark
          >
            <v-icon
              small
              left
              v-text="details.status_code === '200' ? 'mdi-check-all' : 'mdi-close'"
            />
            <span v-text="details.status_code === '200' ? 'Enviado' : 'No Enviado'" />
          </v-chip>
          <!-- <v-btn icon @click="toogle = !toogle">
             <v-icon size="18" color="blue-grey">mdi-close</v-icon>
          </v-btn> -->
        </v-col>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium d-flex align-center justify-space-between pb-0">
          <span class="caption text-muted font-italic">Envíado: </span>
          {{ details.fecha_hora | fullDate }}
        </v-col>
        <v-col cols="12" class="pb-1"><v-divider /></v-col>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium d-flex align-center justify-space-between pb-0">
          <span class="caption text-muted font-italic">Remitente: </span>
          {{ details.remitente }}
        </v-col>
        <v-col cols="12" class="pb-1"><v-divider /></v-col>
        <template v-if="details.status_code !== '200'">
          <v-col cols="12" class="text-subtitle-1 font-weight-medium d-flex align-center justify-space-between pb-0">
            <span class="caption text-muted font-italic">Estatus: </span>
           <span class="red--text text--lighten-1"> {{ details.status_text }} </span>
          </v-col>
          <v-col cols="12" class="pb-1"><v-divider /></v-col>
        </template>
        <v-col cols="12">
          <span class="caption text-muted font-italic d-block pb-3">Enviado a: </span>
          <v-row>
            <v-col cols="6">
              <v-card flat color="blue-grey lighten-5 my-0">
                <v-card-text class="py-0">
                  <v-list-item class="px-0">
                    <v-list-item-icon class="mr-3">
                      <v-avatar
                        color="icono"
                        size="30"
                      >
                        <span class="font-weight-bold white--text">
                          {{ details.nombre_cliente | fisrtLetter }}
                        </span>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        {{ details.nombre_cliente }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="muted-text font-italic">
                        {{ details.destinatario }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" class="d-flex justify-end pa-0">
              <v-btn
                small
                plain
                color="primary"
                style="text-transform: capitalize"
              >Ver todos </v-btn>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="blue-grey--text pt-4 pb-0">
          <h5 class="font-weight-medium black--text">Mensaje</h5>
        </v-col>
        <v-col cols="12" class="blue-grey--text pt-2">
          <p v-text="details.mensaje" />
        </v-col>
      </v-row>
      <template #append>
        <v-row class="grey lighten-5">
          <v-col cols="12" class="d-flex justify-end align-center">
            <v-btn
              class="px-8"
              color="primary"
              dark
              small
              tile
              @click="close"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
</template>
<script>

export default {
  name: 'ViewMessage',
  props: {
    value: Boolean,
    details: {
      type: Object,
      default: () => ({
        co_cli: '',
        destinatario: '',
        fecha_hora: '',
        id: '',
        mensaje: '',
        mensaje_id: '',
        nombre_cliente: '',
        remitente: '',
        status_code: '',
        status_text: '',
      }),
    },
  },
  data () {
    return {
      toogle: this.value,
    }
  },
  watch: {
    toogle (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.toogle = val
    },
  },
  filters: {
    fisrtLetter(val) {
      return val.toUpperCase().charAt(0)
    }
  },
  methods: {
    close() {
      this.toogle = false
    },
  },
}
</script>
